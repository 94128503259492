/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link, graphql } from "gatsby"
import { RiArrowRightLine, RiArrowLeftLine } from "react-icons/ri"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from "../components/layout"
import SEO from '../components/seo';
import { MDXRenderer } from 'gatsby-plugin-mdx'

const styles = {
  'article blockquote': {
    'background-color': 'cardBg'
  },
  pagination: {
    'a': {
      color: 'muted',
      '&.is-active': {
        color: 'text'
      },
      '&:hover': {
        color: 'text'
      }
    }
  }
}

const Pagination = (props) => (
  <div 
    className="pagination -post"
    sx={styles.pagination}
  >
    <ul>
        {(props.previous && props.previous.template === 'blog-post') && (
          <li>
              <Link to={props.previous.slug} rel="prev">
                <p
                  sx={{
                    color: 'muted'
                  }}
                >
                  <span className="icon -left"><RiArrowLeftLine/></span> Previous</p>
                <span className="page-title">{props.previous.title}</span>
              </Link>
          </li>
        )}
        {(props.next && props.next.template === 'blog-post') && (
          <li>
            <Link to={props.next.slug} rel="next">
              <p
                sx={{
                  color: 'muted'
                }}
              >Next <span className="icon -right"><RiArrowRightLine/></span></p>
              <span className="page-title">{props.next.title}</span>
            </Link>
          </li>
        )}
    </ul>
  </div>
)

const Post = ({ data, pageContext }) => {
  const { graphCmsPost } = data // data.markdownRemark holds your post data
  const Image = graphCmsPost.image
  const { previous, next } = pageContext

  let props = {
    previous,
    next
  }

  return (
    <Layout className="page">
      <SEO
        title={graphCmsPost.title}
        description={graphCmsPost.description}
        image={Image}
        article={true}
      />
      <article className="blog-post">
        <header className="featured-banner">
          <section className="article-header">
            <h1>{graphCmsPost.title}</h1>
            <time>{graphCmsPost.date}</time>
          </section>
          {Image ? (
            <GatsbyImage 
              image={getImage(Image)} 
              alt={graphCmsPost.title + ' - Featured image'}
              className="featured-image"
            />
          ) : ""}
        </header>
        <MDXRenderer>{graphCmsPost.content.markdownNode.childMdx.body }</MDXRenderer>
        
      </article>
      {(previous || next) && (
        <Pagination {...props} />
      )}
    </Layout>
  )
}

export default Post

export const pageQuery = graphql`
  query BlogPostQuery($id: String!) {
    graphCmsPost(id: {eq: $id}) {
      id
      slug
      template
      title
      updatedAt
      date
      description
      content {
        markdownNode {
          childMdx {
            body
          }
        }
      }
      image {
        gatsbyImageData(
          layout: FULL_WIDTH 
          quality:80 
          width:1080 
          height:720 
          backgroundColor:"lightgray"
        )
      }
    }
  }
`